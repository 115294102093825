export const charge = {
  name: 'chargeMain',
  path: '/chargeMain',
  component: () => import('@/views_mobile/member/charge'),
  redirect: {
    name: 'charge'
  },
  meta: {
    layout: 'mobile-default',
    text: '베팅리스트',
    gnb: false,
    gnbMenu: 'charge'
  },
  children: [
    {
      name: 'charge',
      path: '/charge',
      component: () => import('@/views_mobile/member/charge/charge'),
      meta: {
        layout: 'mobile-default',
        text: '베팅리스트',
        gnb: false,
        gnbMenu: 'charge'
      }
    }
  ]
}
