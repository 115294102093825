<template>
  <div class="loginmodal">
    <div class="joinwrap">
      <img class="jointopimg" src="@/assets/img/sign_end_icon.png" />
      <h3 class="join-title">가입 신청이 완료 되었습니다.<br />관리자 <span class="gn">승인 대기중</span>입니다.</h3>
      <p class="jointtxt">관리자 승인 후 로그인 하시면 정상적으로 서비스 이용이 가능합니다.<br />당사 사이트는 깨끗하고 안전한 운영을 자랑합니다.</p>
      <ul class="joine">
        <li><img src="@/assets/img/circle_lock.png" /><span>회원님의 모든 데이터는<br />암호화되어 안전하게<br />보관됩니다.</span></li>
        <li><img src="@/assets/img/lock.png" /><span>아이디/비밀번호를<br />분실되지 않도록 보안에<br />신경 써 주세요.</span></li>
        <li><img src="@/assets/img/waste_basket.png" /><span>회원 탈퇴 후<br />회원님의 정보는<br />완전히 삭제됩니다.</span></li>
      </ul>
      <a class="loginnbtn on" @click="$emit('close')">로그인</a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'JoinComplete'
}
</script>

<style scoped>

</style>

<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
